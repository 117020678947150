(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/auth/assets/javascripts/auth-container.js') >= 0) return;  svs.modules.push('/components/marketplace/auth/assets/javascripts/auth-container.js');
'use strict';

const {
  auth
} = svs.components.marketplaceData;
const {
  selectors
} = svs.components.marketplaceData;
const {
  AuthComponent
} = svs.components.marketplace.auth;
const mapStateToProps = (state, _ref) => {
  let {
    groupId,
    permission
  } = _ref;
  const {
    role
  } = selectors.members.selectMe(state, {
    groupId
  }) || {};
  if (!role) {
    return {
      isAuthorized: false
    };
  }
  const checkIfAuthorized = auth.roles.withRole(role);
  return {
    isAuthorized: Array.isArray(permission) ? permission.every(perm => checkIfAuthorized(perm)) : checkIfAuthorized(permission)
  };
};
setGlobal('svs.components.marketplace.auth.AuthContainer', ReactRedux.connect(mapStateToProps)(AuthComponent));

 })(window);