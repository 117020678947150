(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/auth/assets/javascripts/auth-component.js') >= 0) return;  svs.modules.push('/components/marketplace/auth/assets/javascripts/auth-component.js');
'use strict';

const AuthComponent = _ref => {
  let {
    isAuthorized,
    children
  } = _ref;
  return React.createElement(React.Fragment, null, isAuthorized ? children : null);
};
AuthComponent.propTypes = {
  children: PropTypes.node,
  isAuthorized: PropTypes.bool.isRequired
};
setGlobal('svs.components.marketplace.auth.AuthComponent', AuthComponent);

 })(window);